import React, {useState} from 'react'
// import { Context } from './Store';
import logo from '../perzosoft.bmp';
import {Routes, Route, HashRouter, NavLink} from "react-router-dom";
import { Menu } from '../componentes/Menu';
import { Catalogos } from '../componentes/Catalogos';
import { Empresas } from '../componentes/Empresas';
import { Cuentas } from '../componentes/Cuentas';
import { Beneficiarios } from '../componentes/Beneficiarios';
import { Conceptos } from '../componentes/Conceptos';
import { Movimientos } from '../componentes/Movimientos';
import { Reportes } from '../componentes/Reportes';
import { Procesos } from '../componentes/Procesos';

// let nombreCuenta;
let inicial = "sin usuario";

export const Misrutas = () => {
  const [nombreCuenta, setnombreCuenta] = useState(inicial);
  // Simplemente para ilustrar, reemplaza estos valores con la versión real y el nombre del usuario
  const versionPrograma = "1.0.6, 2024-11-15";
  const nombreUsuario = "Perzo";
 
  const nuevacuenta = (id, nombre) =>{
// console.log(nombre);
setnombreCuenta(id + " " + nombre);
window.location.href = "/#/cuentas";
  }
  return (
    <HashRouter>
      <div>

        {/* Barra superior */}
        <nav className="navbar navbar-expand-md navbar-dark bg-dark sticky-top">
          <div className="container-fluid">
            <div className='row align-items-start'>
            <div className='col'>
            <NavLink to="/" className="navbar-brand">
            <img src={logo} className="Nav-logo" alt="logo" />         
            </NavLink>
              </div>
            <div className='col'>
            <button class="btn btn-secondary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
</svg>
            </button>
            </div>
            </div>
           
            
            
            {/* nombre de cuenta*/}
            <span className="navbar-text mr-auto">Versión: {versionPrograma}</span>
            {/* Versión del programa a la izquierda */}
            <span className="navbar-text mr-auto">Cuenta: {nombreCuenta}</span>
            {/* Nombre del usuario a la derecha */}
            <span className="navbar-text">Usuario: {nombreUsuario}</span>
          </div>
        </nav>

        {/* Contenido */}
        <div className="container-fluid">
          <div className="row main">
            {/* Menú lateral */}
            <nav id="offcanvasScrolling" className="col-md-3 col-lg-2 d-md-block sidebar offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" aria-labelledby="offcanvasScrollingLabel">
            {/* <nav id="sidebar" className="col-md-3 col-lg-2 d-md-block sidebar"> bg-light */}
              <div className="sidebar-sticky">
                {/* <NavLink to="/" className="navbar-brand">
      <img src={logo} className="Nav-logo" alt="logo" />
    </NavLink> */}
                <ul className="nav flex-column">
                <li className="nav-item">
                  <div className='offcanvas-header'>
                  <NavLink to="/" className="nav-link">Seleccion</NavLink>
                  <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                  </div>
                    
                  </li>
                  <li className="nav-item">
                    <div className="nav-link dropdown-toggle" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                      Catálogos 
                    </div>
                    {/* Submenú desplegable */}
                    <ul className="submenu collapse" id="collapseExample">
                      <li><NavLink to="/empresas" className="nav-link">Empresas</NavLink></li>
                      <li><NavLink to="/cuentas" className="nav-link">Cuentas</NavLink></li>
                      <li><NavLink to="/beneficiarios" className="nav-link">Beneficiarios</NavLink></li>
                      <li><NavLink to="/conceptos" className="nav-link">Conceptos</NavLink></li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/movimientos" className="nav-link">Movimientos</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/reportes" className="nav-link">Reportes</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/procesos" className="nav-link">Procesos</NavLink>
                  </li>
                </ul>
              </div>
            </nav>

            {/* Contenido principal */}
            <main id="main" role="main" className="ml-sm-auto"> {/* px-md-4 col-lg-10 col-md-9 */}
              <div className="App">
                <Routes>
                  <Route path="/" element={<Menu  nombreCuenta={nombreCuenta} nuevacuenta = {nuevacuenta} />} /*nuevacuenta={nuevacuenta} */ />
                  <Route path="/catalogos" element={<Catalogos />} />
                  <Route path="/empresas" element={<Empresas />} />
                  <Route path="/cuentas" element={<Cuentas />} />
                  <Route path="/beneficiarios" element={<Beneficiarios />} />
                  <Route path="/conceptos" element={<Conceptos />} />
                  <Route path="/movimientos" element={<Movimientos />} />
                  <Route path="/reportes" element={<Reportes />} />
                  <Route path="/procesos" element={<Procesos />} />
                </Routes>
              </div>
            </main>
          </div>
        </div>
      </div>
    </HashRouter>
  );
}
