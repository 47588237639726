import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import React from 'react'

export const Conceptos = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch('http://localhost:4000/conceptos.php');
        const response = await fetch('https://banco.perzosoft.com/conceptos.php');
        if (!response.ok) {
          throw new Error('No se pudo obtener los datos');
        }
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []); // El segundo parámetro [] indica que este efecto se ejecutará solo una vez al montar el componente

  const borrar = (dato) => {
    
    const borrarData = async () => {

      const clave = dato.LLAVE;
       const formData = new FormData();
         formData.append('clave', clave);
 
       try {
         // const response = await fetch('http://localhost:4000/empresas.php');
         const response = await fetch('https://banco.perzosoft.com/conceptoborrar.php',{
           method: "POST",
           body: formData
         });
         if (!response.ok) {
           throw new Error('No se pudo obtener los datos');
         }
         
         const jsonData = await response.json();
               const respuesta = jsonData;
 
               // console.log(respuesta);
 
               if ("dato" in respuesta) {
                                    
           if (respuesta.icono === "success") {
 
         let borrarCuenta =  data.filter(cuenta => cuenta.LLAVE !== respuesta.dato.CLAVE); 
       
        //  console.log(borrarCuenta); 
         setData(borrarCuenta);
         // console.log(borrarEmpresa);
                   } 
                 }

                 if (respuesta.icon === "error") {
                  
                 
                   MySwal.fire({
                     icon: respuesta.icono,
                     title: respuesta.mensaje,
                     showClass: {
                       popup: `
                         animate__animated
                         animate__fadeInUp
                         animate__faster
                       `
                     },
                     showConfirmButton: false,
                     showCancelButton: false,
                     timer: 1500
                   });
                  }
       } catch (error) {
         console.log(error);
       }
     };
    
    const MySwal = withReactContent(Swal);


    MySwal.fire({
     title: "¿Quieres Elimiar el Concepto " + dato.NOMBRE + "?",
     showClass: {
       popup: `
         animate__animated
         animate__fadeInUp
         animate__faster
       `
     },
     text: "No podras revertir esta accion!",
     icon: "warning",
     showCancelButton: true,
     confirmButtonColor: "#3085d6",
     cancelButtonColor: "#d33",
     confirmButtonText: "Borrar!"
   }).then((result) => {
     if (result.isConfirmed) {
       borrarData();
     }
   });
    }

  const modificar = (dato, agrega) => {
    // alert("hola");
    let modal;
    let titulo;
    let marcado;
    let url;

    // console.log(dato);
    

    if (agrega) {
      titulo = "Agrega Concepto";
      url = "https://banco.perzosoft.com/conceptoInsert.php";
      modal=  `
      <form>
      <div class="mb-3">
        <label class="form-label">Cuenta</label>
        <input id="cuenta" class=" form-control" type='tel' name='cuenta' value=''/>
        </div>
        <div class="mb-3">
        <label class="form-label">Sub Cuenta</label>
        <input id="subcuenta"class=" form-control" type='text' name='subcuenta' value=''/>   
        </div>
        <div class="mb-3 form-check form-switch form-check-inline">
        <label class="form-label">Afecta</label>
        <input id="afecta" class="form-check-input" type='checkbox' role="switch" name='afecta' />    
        </div>
        <div class="mb-3">
        <label class="form-label">Nombre</label>
        <input id="nombre" class=" form-control" type='text' name='nombre' value=''/>    
        </div>
    </form>
      `;
    } else {
      if (dato.AFECTA === "S") {
        marcado = "checked";
      } else {
        marcado = "";
      }
      url = "https://banco.perzosoft.com/conceptoUpdate.php";
      modal = `
      <form>
      <div class="mb-3">
        
        <div class="mb-3 form-check form-switch form-check-inline">
        <label class="form-label">Afecta</label>
        <input id="afecta" class="form-check-input" type='checkbox' role="switch" name='afecta' ${marcado} />    
        </div>
        <div class="mb-3">
        <label class="form-label">Nombre</label>
        <input id="nombre" class=" form-control" type='text' name='nombre' value='${dato.NOMBRE}'/>    
        </div>
    </form>
      `
      titulo = "Modificar Concepto <br/>" + dato.NOMBRE;
    }

    const MySwal = withReactContent(Swal);


     MySwal.fire({
      title: titulo,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      html:modal,
      focusConfirm: false,
      showCancelButton: true,
      scrollbarPadding: false,
    
    }).then((result) => {
        
      if (result.isConfirmed) {        
    
         let cuenta;         
         let subcuenta;         
         const nombre = document.querySelector("#nombre").value;
         const afecta = document.querySelector("#afecta");
         const tabla = document.querySelector("#tabla");

         let llave;
         let afectavalor;

         if (afecta.checked) {
          afectavalor = "S";
         } else {
          afectavalor = "N";
         }

        if(agrega){
          let espacio;
          cuenta = document.querySelector("#cuenta").value;         
          subcuenta = document.querySelector("#subcuenta").value;
          switch (subcuenta.length) {
            case 1:
              espacio = "   ";
              break;

            case 2:
              espacio = "  ";
              break;

            case 3:
              espacio = " ";
              break;
          
            default:
              espacio = "   ";
              break;
          }
           llave = cuenta + espacio + subcuenta;
        }else{
          llave = dato.LLAVE;
          cuenta = dato.CTA;
          subcuenta = dato.SCTA;
        }

        // console.log(afectavalor);
        //  return;
        const formData = new FormData();
        formData.append('llave', llave);
        formData.append('cuenta', cuenta);
      formData.append('subcuenta', subcuenta);
      formData.append('nombre', nombre);
      formData.append('afecta', afectavalor);
          const EnviarData = async () => {

            try {
              // const response = await fetch('http://localhost:4000/empresas.php');
              const response = await fetch(url,{
                method: "POST",
                body: formData
            });
              if (!response.ok) {
                throw new Error('No se pudo obtener los datos');
              }
              const jsonData = await response.json();
              const respuesta = jsonData;

              // console.log(respuesta);

              if ("dato" in respuesta) {
                 
                if (agrega) {
                  
                  if (respuesta.icono === "success") {
                  
                    let tr = document.createElement("tr");
                    tr.id = respuesta.dato.CLAVE;
                  tr.innerHTML = `
                  <td id='cuenta${respuesta.dato.LLAVE.replace(/ /g, "")}' onClick="modificar(${respuesta.dato}, false)">${respuesta.dato.CTA}</td>
                  <td id='subcuenta${respuesta.dato.LLAVE.replace(/ /g, "")}' onClick="modificar(${respuesta.dato}, false)">${respuesta.dato.SCTA}</td>
                  <td id='afecta${respuesta.dato.LLAVE.replace(/ /g, "")}' onClick="modificar(${respuesta.dato}, false)">${respuesta.dato.AFECTA}</td>
                  <td id='nombre${respuesta.dato.LLAVE.replace(/ /g, "")}' onClick="modificar(${respuesta.dato}, false)">${respuesta.dato.NOMBRE}</td>
                  <td ><button onClick="borrar(${respuesta.dato})" type="button" class="btn btn-danger"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
        </svg></button></td>
                  `;
                  
        tabla.appendChild(tr);
        data.push({CLAVE: respuesta.dato.CLAVE, NOMBRE: respuesta.dato.NOMBRE}); 
        
                  } 
                 
                } else {
                  
                  
                  if (respuesta.icono === "success") {
                  
                    data.forEach(element => {
                      
                      if (element.CLAVE === respuesta.dato.CLAVE) {
                        
                       
                        
                        const Cambiarnombre = document.querySelector(`#nombre${respuesta.dato.LLAVE.replace(/ /g, "")}`);
                        const Cambiarsubcuenta = document.querySelector(`#subcuenta${respuesta.dato.LLAVE.replace(/ /g, "")}`);
                        const Cambiarcuenta = document.querySelector(`#cuenta${respuesta.dato.LLAVE.replace(/ /g, "")}`);
                        const Cambiarafecta = document.querySelector(`#afecta${respuesta.dato.LLAVE.replace(/ /g, "")}`);
                        // console.log(respuesta.dato.CTA);
                        
                        Cambiarnombre.innerHTML = respuesta.dato.NOMBRE;
                        Cambiarcuenta.innerHTML = respuesta.dato.CTA;
                        Cambiarsubcuenta.innerHTML = respuesta.dato.SCTA;
                        Cambiarafecta.innerHTML = respuesta.dato.AFECTA;

                        element.AFECTA = respuesta.dato.AFECTA;
                        element.NOMBRE = respuesta.dato.NOMBRE;
                        element.CTA = respuesta.dato.CTA;
                        element.SCTA = respuesta.dato.SCTA;
                      }
                    });
                    
                  } 

                }
                
                
               }

               if (respuesta.icon === "error") {
               MySwal.fire({
                icon: respuesta.icono,
                title: respuesta.mensaje,
                showClass: {
                  popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                  `
                },
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500
              });
            }
            } catch (error) {
             console.log(error.message); 
            }
          };

          EnviarData();
      }
    
    });
 

    

  }


  if (loading) {
    // Muestra la animación de carga y el logo mientras se están cargando los datos
    return (    
      <div className="App-body">
    
    <section className= "seccion">
    <h1>Conceptos</h1>

    

    <div className="justify-content-center align-items-center">
      
    <br/>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
      </div>
    </section>
    </div>
      
    );
  }
  if (error) return <p>Error: {error}</p>;
  if (!data) return null; 
  return (    
        
    <div className="App-body">
   
    <section className= "seccion">
    <h1>Conceptos</h1>

    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button onClick={() => modificar(null, true)} type="button" className="btn btn-primary Seleccionar_ok mb-4"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill-add" viewBox="0 0 16 16">
  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
  <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>
</svg></button>
</div>

    <table class="table table-striped table-hover">
  <thead>
    <tr>
      
      <th scope="col">CUENTA</th>
      <th scope="col">SUB CUENTA</th>
      <th scope="col">AFECTA</th>
      <th scope="col">NOMBRE</th>
      <th scope="col">ACCIONES</th>
    </tr>
  </thead>
  <tbody id="tabla">
  {data.map(item => (
          <tr>
          <td id={`cuenta${item.LLAVE.replace(/ /g, "")}`} onClick={() => modificar(item)}>{item.CTA}</td>
          <td id={`subcuenta${item.LLAVE.replace(/ /g, "")}`} onClick={() => modificar(item)}>{item.SCTA}</td>
          <td id={`afecta${item.LLAVE.replace(/ /g, "")}`} onClick={() => modificar(item)}>{item.AFECTA}</td>
          <td id={`nombre${item.LLAVE.replace(/ /g, "")}`} onClick={() => modificar(item)}>{item.NOMBRE}</td>
          <td>
            {/* <button onClick={() => modificar(item)} type="button" className="btn btn-warning"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-archive-fill" viewBox="0 0 16 16">
  <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1M.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8z"/>
</svg></button> */}
 <button onClick={() => borrar(item)} type="button" className="btn btn-danger"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
</svg></button> </td>
        </tr>
        ))}
    
  </tbody>
</table>

    </section>
    </div>
    
  )
}
