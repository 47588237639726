// import React from 'react'
import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const Beneficiarios = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [flujo, setFlujo] = useState(null);
  const [error, setError] = useState(null);

 
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch('http://localhost:4000/beneficiarios.php');
        const response = await fetch('https://banco.perzosoft.com/beneficiarios.php');
        if (!response.ok) {
          throw new Error('No se pudo obtener los datos');
        }
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    const Allflujo = async () => {
      try {
        // const response = await fetch('http://localhost:4000/back.php');
        const response = await fetch('https://banco.perzosoft.com/conceptos.php');
        if (!response.ok) {
          throw new Error('No se pudo obtener los datos');
        }
        const jsonData = await response.json();
        setFlujo(jsonData);
      } catch (error) {
        setError(error.message);
      
      }
    };

    fetchData();
    Allflujo();
  }, []); // El segundo parámetro [] indica que este efecto se ejecutará solo una vez al montar el componente

  const borrar = (dato) => {
    
    const borrarData = async () => {

      const clave = dato.CLAVE;
       const formData = new FormData();
         formData.append('clave', clave);
 
       try {
         // const response = await fetch('http://localhost:4000/empresas.php');
         const response = await fetch('https://banco.perzosoft.com/beneficiariosborrar.php',{
           method: "POST",
           body: formData
         });
         if (!response.ok) {
           throw new Error('No se pudo obtener los datos');
         }
         
         const jsonData = await response.json();
               const respuesta = jsonData;
 
               // console.log(respuesta);
 
               if ("dato" in respuesta) {
                                    
           if (respuesta.icono === "success") {
 
         let borrarCuenta =  data.filter(cuenta => cuenta.CLAVE !== respuesta.dato.CLAVE); 
       
         setData(borrarCuenta);
         // console.log(borrarEmpresa);
                   } 
                 }
                 if (respuesta.icon === "error") {
                   MySwal.fire({
                     icon: respuesta.icono,
                     title: respuesta.mensaje,
                     showClass: {
                       popup: `
                         animate__animated
                         animate__fadeInUp
                         animate__faster
                       `
                     },
                     showConfirmButton: false,
                     showCancelButton: false,
                     timer: 1500
                   });
                  }
       } catch (error) {
         console.log(error);
       }
     };
    
    const MySwal = withReactContent(Swal);


    MySwal.fire({
     title: "¿Quieres Elimiar el Beneficiario " + dato.NOMBRE + "?",
     showClass: {
       popup: `
         animate__animated
         animate__fadeInUp
         animate__faster
       `
     },
     text: "No podras revertir esta accion!",
     icon: "warning",
     showCancelButton: true,
     confirmButtonColor: "#3085d6",
     cancelButtonColor: "#d33",
     confirmButtonText: "Borrar!"
   }).then((result) => {
     if (result.isConfirmed) {
       borrarData();
     }
   });
    }

  const modificar = (dato, agrega) => {
    // alert("hola");
    let modal;
    let titulo;
    let url;
    
//     <span class="btn btn-primary ms-3" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
//    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
//  </svg>
//  <select id='buscar2' class="btn btn-primary ms-2" > 
// <option value="">Selecciona</option>
//  </select>
//  </span>

    if (agrega) {
      titulo = `Nuevo Beneficiario`;
      url = "https://banco.perzosoft.com/beneficiarioInsert.php";
      modal = `
      <form class="row g-3 needs-validation" >

      <div class="mb-3">
      <label for="validationCustom03" class="form-label">Clave</label>
    <input id='clave' type="text" class="form-control" name='clave' required>
    </div>

    <div class="mb-3">
      <label for="validationCustom01" class="form-label">Nombre </label>
      <input id='nombre' class="form-control" type='text' name='nombre' required/>
    </div>
  
    <div class="mb-3">
    <label class="form-label">Concepto</label>
    <input id='concepto' class="form-control" type='text' name='concepto' required/>
    </div>
  
    <div class="mb-3">
      <label for="validationCustom03" class="form-label">Importe</label>
      <input id='importe' type="text" class="text-end form-control" name='importe' required>
    </div>
  
    <div class="mb-3">
    <label for="validationCustom03" class="form-label">Buscar Concepto</label>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
   <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
 </svg>
 <input id='buscar' type="text" class="form-control" name='bus' > 
 </div>

 <span class="btn btn-primary ms-3" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
   <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
 </svg>
 <select id='buscar2' class="btn btn-primary ms-2" > 
<option value="">Selecciona</option>
 </select>
 </span>

    <div class="col-md-3">
    <label for="validationCustom03" class="form-label">Cuenta</label>
    <input id='div' type="text" class="form-control" name='cuenta' value='' required>
    </div>
  
    <div class="col-md-3">
      <label for="validationCustom03" class="form-label">SubCuenta</label>
    <input id='div2' type="text" class="form-control" name='subcuenta' value='' required>
    </div>
    
    <div class="col-md-3">
      <label for="validationCustom03" class="form-label">Descripcion</label>
      <div id="inempresa"></div> 
    </div>
    
  </form>
        `;
    } else {
      titulo = `Clave: ${dato.CLAVE}`;
      url = "https://banco.perzosoft.com/beneficiarioUpdate.php";
      modal = `
      <form class="row g-3 needs-validation" >
    <div class="mb-3">
      <label for="validationCustom01" class="form-label">Nombre </label>
      <input id='nombre'class="form-control" type='text' name='nombre' value='${dato.NOMBRE}' required/>
    </div>
  
    <div class="mb-3">
    <label class="form-label">Concepto</label>
    <input id='concepto' class="form-control" type='text' name='concepto' value='${dato.CONCEPTO}' required/>
    </div>
  
    <div class="mb-3">
      <label for="validationCustom03" class="form-label">Importe</label>
      <input id='importe' type="text" class="text-end form-control" name='importe' value='${dato.CANTIDAD}' required>
    </div>
  
    <div class="mb-3">
    <label for="validationCustom03" class="form-label">Buscar Concepto</label>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
   <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
 </svg>
 <input id='buscar' type="text" class="form-control" name='bus' > 
 </div>

    <span class="btn btn-primary ms-3" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
   <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
 </svg>
 <select id='buscar2' class="btn btn-primary ms-2" > 
<option value="">Selecciona</option>
 </select>
 </span>

    <div class="col-md-3">
    <label for="validationCustom03" class="form-label">Cuenta</label>
    <input id='div' type="text" class="form-control" name='cuenta' value='${dato.CTA}' required>
    </div>
  
    <div class="col-md-3">
      <label for="validationCustom03" class="form-label">SubCuenta</label>
    <input id='div2' type="text" class="form-control" name='subcuenta' value='${dato.SCTA}' required>
    </div>
    
    <div class="col-md-3">
      <label for="validationCustom03" class="form-label">Descripcion</label>
      <div id="inempresa"></div> 
    </div>
    
  </form>
        `;
    }
    const MySwal = withReactContent(Swal);


     MySwal.fire({
      title: titulo,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      html: modal,
      focusConfirm: false,
      showCancelButton: true,
      scrollbarPadding: false,
    }).then((result) => {
        
      if (result.isConfirmed) {        
      
        let clave;
        if(agrega){
           clave = document.querySelector("#clave").value;
        }else{
           clave = dato.CLAVE;
        }
        
         const cuenta = document.querySelector("#div").value;         
         const subcuenta = document.querySelector("#div2").value;         
         const nombre = document.querySelector("#nombre").value;
         const concepto = document.querySelector("#concepto").value;
         const importe = document.querySelector("#importe").value;
         const tabla = document.querySelector("#tabla");

        // console.log(cuenta, subcuenta, nombre, concepto, importe, tabla);
        //  return;
        const formData = new FormData();
        formData.append('clave', clave);
        formData.append('cuenta', cuenta);
      formData.append('subcuenta', subcuenta);
      formData.append('nombre', nombre);
      formData.append('concepto', concepto);
      formData.append('importe', importe);
          const EnviarData = async () => {

            try {
              // const response = await fetch('http://localhost:4000/empresas.php');
              const response = await fetch(url,{
                method: "POST",
                body: formData
            });
              if (!response.ok) {
                throw new Error('No se pudo obtener los datos');
              }
              const jsonData = await response.json();
              const respuesta = jsonData;

              // console.log(respuesta);

              if ("dato" in respuesta) {
                 
                if (agrega) {
                  
                  if (respuesta.icono === "success") {
                  
                    let tr = document.createElement("tr");
                    tr.id = respuesta.dato.CLAVE;
                  tr.innerHTML = `<th id='clave${respuesta.dato.CLAVE}' onClick="modificar(${respuesta.dato}, false)">${respuesta.dato.CLAVE}</th>
                  <td id='nombre${respuesta.dato.CLAVE}' onClick="modificar(${respuesta.dato}, false)">${respuesta.dato.NOMBRE}</td>
                  <td id='concepto${respuesta.dato.CLAVE}' onClick="modificar(${respuesta.dato}, false)">${respuesta.dato.CONCEPTO}</td>
                  <td ><button onClick="borrar(${respuesta.dato})" type="button" class="btn btn-danger"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
        </svg></button></td>
                  `;
                  
        tabla.appendChild(tr);
        data.push({CLAVE: respuesta.dato.CLAVE, NOMBRE: respuesta.dato.NOMBRE}); 
        
                  } 
                 
                } else {
                  
                  
                  if (respuesta.icono === "success") {
                  
                    data.forEach(element => {
                      
                      if (element.CLAVE === respuesta.dato.CLAVE) {
                        
                        const Cambiarconcepto = document.querySelector(`#concepto${respuesta.dato.CLAVE}`);
                        const Cambiarnombre = document.querySelector(`#nombre${respuesta.dato.CLAVE}`);
                        console.log(respuesta.dato.CTA);
                        Cambiarconcepto.innerHTML = respuesta.dato.CONCEPTO;
                        Cambiarnombre.innerHTML = respuesta.dato.NOMBRE;

                        element.CONCEPTO = respuesta.dato.CONCEPTO;
                        element.NOMBRE = respuesta.dato.NOMBRE;
                        element.CANTIDAD = respuesta.dato.CANTIDAD;
                        element.CTA = respuesta.dato.CTA;
                        element.SCTA = respuesta.dato.SCTA;
                      }
                    });
                    
                  } 

              
                }
                
                
               }
               if (respuesta.icon === "error") {
               MySwal.fire({
                icon: respuesta.icono,
                title: respuesta.mensaje,
                showClass: {
                  popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                  `
                },
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2500
              });
            }
            } catch (error) {
             console.log(error.message); 
            }
          };

          EnviarData();
      }
    
    });
 

    const input = document.querySelector("#inempresa");
    const div = document.querySelector("#div");
    const div2 = document.querySelector("#div2"); 
    const buscar = document.querySelector("#buscar");
    const buscar2 = document.querySelector("#buscar2");
    let datasent;

    flujo.forEach(Element => {
      const opcion = document.createElement("option");
       opcion.value = Element.CTA + "," + Element.SCTA;
       opcion.innerHTML = Element.NOMBRE;
       buscar2.appendChild(opcion);
 
       // <option value="volvo">Volvo</option>
      });
    
    const validation = () =>{

      const fetchData = async () => {
        
        ReactDOM.render(                  
          <div className="spinner-border mt-2" role="status"></div>,
          input
        ); 

        
      try {
        datasent = {cuenta: div.value, subcuenta: div2.value}
      const response = await fetch('https://banco.perzosoft.com/conceptobus.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(datasent)
   
      });
            if (!response.ok) {
              throw new Error('No se pudo obtener los datos');
            }
            const jsonData = await response.json();

            
              if (jsonData === null || jsonData === "") {

                ReactDOM.render(                  
                  <p class='mt-2 fw-bold text-danger'>No se encontro ninguna descripcion</p>,
                  input
                );
              
              } else {
                
                ReactDOM.render(                  
                  <p class='mt-2 fw-bold text-primary' >{jsonData[0].NOMBRE}</p>,
                  input
                );
              }
            
            
          } catch (error) {
            setError(error.message);
          }

          
        };

        fetchData();
      }
    
      div.addEventListener("input", validation);
      div2.addEventListener("input", validation);
      buscar.addEventListener("input", function (e) {
        
        const formData = new FormData();
        formData.append('nombre', buscar.value);

        const fetchData = async () => {
        
          // ReactDOM.render(                  
          //   <div className="spinner-border mt-2" role="status"></div>,
          //   input
          // ); 
  
          
        try {
          
        const response = await fetch('https://banco.perzosoft.com/beneficiariosBus.php', {
          method: 'POST',
          body: formData
        });
          if (!response.ok) {
            throw new Error('No se pudo obtener los datos');
          }
              
          const jsonData = await response.json();

          // console.log(respuesta); return;
              
                if (jsonData === null || jsonData === "") {
  
                  ReactDOM.render(                  
                    <p class='mt-2 fw-bold text-danger'>No se encontro ninguna descripcion</p>,
                    input
                  );
                
                } else {
                  // removeChild(child)
                  // console.log(flujo);
                  buscar2.innerHTML = "";      
                  jsonData.forEach(Element => {
                    const opcion = document.createElement("option");
                     opcion.value = Element.CTA + "," + Element.SCTA;
                     opcion.innerHTML = Element.NOMBRE;
                     buscar2.appendChild(opcion);
               
                     // <option value="volvo">Volvo</option>
                    });
                  
                  // ReactDOM.render(                  
                  //   <p class='mt-2 fw-bold text-primary' >{jsonData[0].NOMBRE}</p>,
                  //   input
                  // );
                  // div.value = jsonData[0].CTA;
                  // div2.value = jsonData[0].SCTA;
                }
              
              
            } catch (error) {
              setError(error.message);
            }
  
            
          };
  
          fetchData();

        // console.log(datos);
        
      });


       
      buscar2.addEventListener("change", function (e) {
        let selectedOption = this.options[buscar2.selectedIndex];

        // console.log(selectedOption.text); return;
        ReactDOM.render(                  
          <p class='mt-2 fw-bold text-primary' >{selectedOption.text}</p>,
          input
        );
        
        let datos = e.target.value.split(",");
        // console.log(datos);
        div.value = datos[0];
        div2.value = datos[1];

        // console.log(e.target.value);
        // console.log(selectedOption.text);
      });
    
      validation();
  }

  if (loading) {
    // Muestra la animación de carga y el logo mientras se están cargando los datos
    return (    
      <div className="App-body">
    
      <section className= "seccion">
      <h1>Beneficiarios</h1>
  
      <div className="justify-content-center align-items-center">
        
      <br/>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        </div>
      </section>
      </div>
      
    );
  }
  if (error) return <p>Error: {error}</p>;
  if (!data) return null; 
  return (
    <div className="App-body">
    
    <section className= "seccion">
    <h1>Beneficiarios</h1>

    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button onClick={() => modificar(null, true)} type="button" className="btn btn-primary Seleccionar_ok mb-4"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill-add" viewBox="0 0 16 16">
  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
  <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4"/>
</svg></button>
</div>

    <table class="table table-striped table-hover">
  <thead>
    <tr>
      <th scope="col">CLAVE</th>
      <th scope="col">NOMBRE</th>
      <th scope="col">CONCEPTO</th>          
      <th scope="col">ACCIONES</th>
    </tr>
  </thead>
  <tbody id="tabla">
  {data.map(item => (
          <tr>
          <th id={`clave${item.CLAVE}`} onClick={() => modificar(item, false)}scope="row">{item.CLAVE}</th>
          <td id={`nombre${item.CLAVE}`}onClick={() => modificar(item, false)}>{item.NOMBRE}</td>
          <td id={`concepto${item.CLAVE}`}onClick={() => modificar(item, false)}>{item.CONCEPTO}</td>          
          <td>
            {/* <button onClick={() => modificar(item)} type="button" className="btn btn-warning"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-archive-fill" viewBox="0 0 16 16">
  <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1M.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8z"/>
</svg></button> */}
 <button onClick={() => borrar(item)} type="button" className="btn btn-danger"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
</svg></button> </td>
        </tr>
        ))}
    
  </tbody>
</table>

    </section>
    </div>
  )
}
